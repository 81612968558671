<template>
  <base-section id="establish-your-manufacturing-footprint">
    <base-section-heading
      title="ESTABLISH YOUR MANUFACTURING FOOTPRINT"
      icon="mdi-lightbulb-on-outline"
      outlined
    >
      We specialize in advancing your business through technology transfer, acquisitions, and polymer development throughout Asia and North America. Leverage on our extensive network in the chemical and polymer market. Initiate international business connections and distribution channels.
    </base-section-heading>

    <v-container>
      <v-carousel
        hide-delimiters
        cycle
        interval="3000"
        height="600"
      >
        <v-carousel-item
          v-for="n in 4"
          :key="n"
          :src="require(`@/assets/industry-${n}.jpg`)"
          reverse-transition="fade-transition"
          transition="fade-transition"
        />
      </v-carousel>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionEstablishYourManufacturingFootprint',
  }
</script>
